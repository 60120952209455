.instruction {
  margin-top: 80px;
}

.blocks {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  flex-wrap: wrap;
  //@media screen and (max-width: 768px) {
  //  flex-direction: column;
  //}
}

.block {
  flex: 1;
  @media screen and (max-width: 840px) {
    flex: unset;
    width: calc((100% - 20px) / 2 - 32px);
  }
  @media screen and (max-width: 600px) {
    flex: unset;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 8px 16px 20px 16px;
  border-radius: 8px;
  background-color: var(--color-background-green);

  .number {
    color: var(--color-purple);
  }

}