.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  //@media screen and (max-width: 840px) {
  //  flex-direction: column;
  //}

  .card {
    position: relative;
    width: calc((100% - 80px) / 3);
    @media screen and (max-width: 930px) {
      width: calc((100% - 40px) / 3);
    }
    @media screen and (max-width: 840px) {
      width: calc((100% - 20px) / 2);
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border-radius: 16px;
      border: 1px solid var(--color-border);

      .logo {
        width: 216px;
        height: 72px;
        margin: auto;
      }
      .title {
        margin: auto;
        font-size: 24px;
        font-weight: bold;
        color: var(--color-purple);
      }
      .line {
        display: flex;
        justify-content: space-between;
      }

    }


  }

}