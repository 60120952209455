.header {
  width: calc(100% - 30px);
  max-width: 1160px;
  padding: 0 15px 0 15px;
  margin: 40px auto;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 145px;
    height: 64px;
  }
}