@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

//font-family: "Dela Gothic One", sans-serif;

$brand: #00A6CE;
$action: #5CD6CE;
$border: #E2E5E9;
$text: #29393D;
$white: #FFFFFF;

:root {
  --color-purple: #B3598F;
  --color-green: #87C174;

  --color-white: #FFFFFF;
  --color-text: #474747;

  --color-background-green: #E7F3E3;
  --color-background-blue: #E2F2FD;

  --color-border: #E2E5E9;
}

// App
body, html {
  background: #fff;
  color: var(--color-text);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  margin: 0;
  height: 100%;
}
title {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: $brand;
  margin: 32px 0;
}

h2 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: bold;
  color: $text;
}

h3 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  color: $text;
}

// content
.content {
  max-width: 1160px;
  margin: auto;
  padding: 0 15px 0px 15px;
}

.bold {
  font-weight: bold;
}

.caption {
  font-size: 12px;
  font-weight: normal;
}

.highlight {
  font-family: 'Dela Gothic One', sans-serif;
  font-size: 48px;
  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
}

.green {
  color: var(--color-green);
}