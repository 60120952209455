.banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    justify-content: flex-start;
    gap: 20px;
  }
  
  height: 460px;
  @media screen and (max-width: 1024px) {
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    height: 500px;
  }
  @media screen and (max-width: 500px) {
    height: 600px;
  }

  padding: 80px;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  
  border-radius: 8px;
  background-color: #3F3F3F;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #FFFFFF;

  .title {
    width: 500px;
    @media screen and (max-width: 768px) {
      width: 400px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .slider {
    width: 100%;
    max-width: 380px;
    @media screen and (max-width: 600px) {
      max-width: unset;
    }

    .label {
      display: flex;
      justify-content: space-between;
    }

  }
  
}