.registration {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.form {
  max-width: 560px;
  margin: auto;
}

.code {
  max-width: 560px;
  margin: auto;
}

.done {
  max-width: 560px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;

}