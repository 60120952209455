.footer {
  margin-top: 80px;
  background-color: var(--color-green);

  .content {

    display: flex;
    gap: 40px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }

    max-width: 1160px;
    margin: auto;
    padding: 40px 15px 40px 15px;

    color: var(--color-white);
    font-size: 12px;


    .logo {

      width: 260px;

      img {
        width: 90px;
        height: 40px;
      }

      .caption {
        p {
          margin-top: 0;
          margin-bottom: 8px;
        }
      }

    }

    .policy {

      display: flex;
      flex-direction: column;
      gap: 8px;

      width: calc(100% - 40px - 260px);
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .legal {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        span {
          white-space: nowrap;
        }
        a {
          color: #FFFFFF;
        }
      }

    }



  }

}